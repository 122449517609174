import React from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';

import { useNavigate } from '@reach/router';
import SEO from '../components/seo';

import { Header, Footer, Content } from '../components/layouts';

const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/');
  return (
    <>
      <SEO title="404: Not found" />
      <Header logo />
      <Divider />
      <Content>
        <Box display="block" align="center">
          <Typography style={{ fontSize: '80px' }}>&#128584;</Typography>
          <h1>NOT FOUND</h1>
          <p>Oops... There is nothing here.</p>
          <Box mt={4}>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Lets go home?
            </Button>
          </Box>
        </Box>
      </Content>
      <Divider />
      <Footer />
    </>
  );
};

export default NotFound;
